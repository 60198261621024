export default {
  blue: "#1DEFF4",
  yellow: "#FFE116",
  orange: "#F89143",
  darkOrange: "#E27725",

  green: "#000",
  white: "white"

};
